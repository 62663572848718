<template>
    <div id="forgetPasswordPage">


         <div  v-if="isSendEmail == false" >
            <LoginFormComponent
                ref="loginFormComponent"
                :isExpired="resultExp"
                formType="FORGOT_PASSWORD"
                :formTitle="$t('FORGOT_PASSWORD_PAGE_FORM_TITLE_FORGOT_PASSWORD')"
                :textDont="$t('FORGOT_PASSWORD_PAGE_TEXT_DONT')"
                :btnLabel="$t('FORGOT_PASSWORD_PAGE_BUTTON_LABEL')"
                :label1="$t('FORGOT_PASSWORD_PAGE_LABEL_1')"
                :text6="$t('FORGOT_PASSWORD_PAGE_TEXT_6')"
                @send="send"
                :rulesRequired=true
                :rulesEmail=true
                :siteKey="siteKey"
            />
<!--            <div class="row box-container">-->
<!--                <div class="col-xl-12">-->
<!--                     <div class="return-login " @click="toLogin()"> Back to Login </div>-->
<!--                </div>-->

<!--            </div>-->

        </div>
        <div v-else class="d-flex justify-content-center align-items-center min-vh-100 flex-column">
            <div class="logo-moonstake">
                <img src="@/assets/images/logo_horizontal_black.svg" />
            </div>
             <CCard class="p-4">
                <CCardBody class="custom-card-body">
                    <h1 class="title mb-4">{{$t("FORGOT_PASSWORD_PAGE_TITLE_RESET_PASSWORD")}}</h1>
                    <p v-html="$t(`FORGOT_PASSWORD_PAGE_CONTENT_SENT_TO_EMAIL`, {email: email})"></p>
                    <p>{{$t("FORGOT_PASSWORD_PAGE_CONTENT_NOTIFY_CHECK_RESET_LINK")}}</p>
                    <br>
                    <p>{{$t("FORGOT_PASSWORD_PAGE_QUESTION_RECEIVE_EMAIL")}}</p>
                    <p>{{$t("FORGOT_PASSWORD_PAGE_CONTENT_CHECK_SPAM")}}<a href="#" @click="resend(email, fakeCaptcha)" >{{$t("FORGOT_PASSWORD_PAGE_BUTTON_RESEND")}}</a>{{$t("FORGOT_PASSWORD_PAGE_TEXT_EMAIL")}}</p>
<!--                    <div class="text-center"> -->
<!--                       <button type="button" @click="toLogin()" class="btn btn-primary">Back to login</button>-->
<!--                    </div>-->
                </CCardBody>
             </CCard>

        </div>
    </div>
</template>

<script>
    import LoginFormComponent from "../../components/LoginFormComponent";
    import SmallModalComponent from "../../components/SmallModalComponent";
    import endpoints from "../../constants/endpoints";

    export default {
    data() {
        return {
            isSendEmail:false,
            siteKey: process.env.VUE_APP_SITEKEY || '6LeMwqIUAAAAANsGDacL3nksMsczverr3POAikdl',
            email: "",
            fakeCaptcha: "",
            isExpired__: false,
        }
    },
    computed: {
        resultExp () {
            return  this.$store.state.platform.checkExpired;
        }
    },
    methods: {
        async send({ email, captchaToken }) {
            if (!captchaToken) return;
            try {
                const params = {email, 'g-recaptcha-response': captchaToken};
                this.fakeCaptcha = captchaToken;
                let result = await this.$http.post(endpoints.forgotPassword, params);
                console.log(result);
                if(result){
                    this.isSendEmail = true;
                    this.email = email;
                    this.$notify({
                        group: 'notifications',
                        type: 'success',
                        title: this.$t("FORGOT_PASSWORD_PAGE_TITLE_NOTIFY_SUCCESS"),
                        text: this.$t("FORGOT_PASSWORD_PAGE_TEXT_NOTIFY_SUCCESS"),
                    });
                }
            } catch (e) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: this.$t("FORGOT_PASSWORD_PAGE_TITLE_NOTIFY_ERROR"),
                    text: this.$t("FORGOT_PASSWORD_PAGE_TEXT_NOTIFY_ERROR")
                });
                this.$refs.loginFormComponent.resetCaptcha();
            }

        },
        async resend(email,fakeCaptcha){
            try {
                const params = {email, 'g-recaptcha-response': fakeCaptcha};
                let result = await this.$http.post(endpoints.forgotPassword, params);
                if(result){
                    this.isSendEmail = true;
                    this.email = email;
                    this.$notify({
                        group: 'notifications',
                        type: 'success',
                        title: this.$t("FORGOT_PASSWORD_PAGE_TITLE_NOTIFY_SUCCESS"),
                        text: this.$t("FORGOT_PASSWORD_PAGE_TEXT_NOTIFY_SUCCESS"),
                    });
                }
            } catch (e) {
                this.$notify({
                    group: 'notifications',
                    type: 'error',
                    title: this.$t("FORGOT_PASSWORD_PAGE_TITLE_NOTIFY_ERROR"),
                    text: this.$t("FORGOT_PASSWORD_PAGE_TEXT_NOTIFY_ERROR")
                });
                this.$refs.loginFormComponent.resetCaptcha();
            }
        },
        toLogin(){
            this.$router.push("/login");
        }
    },
    name: "ForgetPassword",
    components: {LoginFormComponent, SmallModalComponent}
}
</script>

<style scoped lang="scss">
#forgetPasswordPage /deep/{
    .flex-column{
        flex-direction: column;
    }
    .logo-moonstake{
        margin-bottom: 40px;
    }
    .custom-card-body {
        @media (min-width: 1100px) {
            width: 100%;
        }
        // .input1{
        //     margin: 30px 0 15px 20px;
        // }
        .captcha {
            margin: 15px 0 10px 0;
            @media (max-width: 700px) {
                order: 2;
            }
        }
        .forgot-des{
            padding-bottom: 0;
        }
        .forgot-text {
            margin-top: 3px;
            @media (max-width: 700px) {
                text-align: right;
                margin-right: -10px;
                margin-bottom: -10px;
            }
        }
        p{
            color: #3E4B65;
            font-size: 14px;
            font-weight: 300;
            line-height: 22px;
            margin:0;
            strong{
                font-weight: bold;
            }
            a{
                color: #2B7EF1;
            }
        }
    }
}
.error-msg{
  color: red;
}
</style>
